import { css } from '@emotion/react'
import { LovBranchesSearchResponse } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { Flex } from 'driverama-core/components/Flex'
import { toast } from 'driverama-core/components/toast/Toast'
import IconChevronDown from 'driverama-core/images/icons/IconChevronDown.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { LINKS } from '../../constants/links'
import { useWizard } from '../../sections/wizard/WizardContext'
import {
  SIconWrapper,
  SSidebarButton,
  SSidebarButtonMenuItem,
  SSidebarButtonWrapper,
  STextWrapper
} from './SidebarButton.styled'

interface SidebarButtonProps {
  branch?: LovBranchesSearchResponse['content'][number]
}

export function SidebarButton(props: SidebarButtonProps) {
  const { t } = useTranslation(['core'])
  const router = useRouter()

  const { state } = useWizard()

  return (
    <SSidebarButtonWrapper>
      <Dropdown
        placement="right-start"
        padding="none"
        css={css`
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `}
        trigger={
          <SSidebarButton variant="primary">
            <STextWrapper>{t('core:create_new')}</STextWrapper>
            <SIconWrapper>
              <IconChevronDown />
            </SIconWrapper>
          </SSidebarButton>
        }
      >
        <Flex variant="column" gap={1}>
          <SSidebarButtonMenuItem
            onClick={() => {
              if (!state.branchId) {
                toast({
                  type: 'error',
                  content: t('core:choose_branch'),
                  error: null
                })
                return
              }

              router.push(LINKS.appointment(state.branchId))
            }}
          >
            {t('core:appointment_buying')}
          </SSidebarButtonMenuItem>

          {driverama.flags.IS_HANDOVER_ENABLED_APPS_31 && (
            <SSidebarButtonMenuItem
              onClick={() => {
                if (!state.branchId) {
                  toast({
                    type: 'error',
                    content: t('core:choose_branch'),
                    error: null
                  })
                  return
                }

                router.push(LINKS.handover_customer(state.branchId))
              }}
            >
              {t('core:appointment_handover')}
            </SSidebarButtonMenuItem>
          )}

          <SSidebarButtonMenuItem
            onClick={() => {
              if (!state.branchId) {
                toast({
                  type: 'error',
                  content: t('core:choose_branch'),
                  error: null
                })
                return
              }

              router.push(LINKS.reclamation_customer(state.branchId))
            }}
          >
            {t('core:appointment_reclamation')}
          </SSidebarButtonMenuItem>

          {props.branch?.homeDelivery && (
            <Link href={LINKS.disable_homedelivery(props.branch.id)} passHref>
              <SSidebarButtonMenuItem>
                {t('core:disable_homedelivery')}
              </SSidebarButtonMenuItem>
            </Link>
          )}
        </Flex>
      </Dropdown>
    </SSidebarButtonWrapper>
  )
}
