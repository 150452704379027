import { Checkbox } from 'driverama-core/components/checkbox/Checkbox'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'
import { useTranslation } from 'react-i18next'
import { useWizard } from '../../sections/wizard/WizardContext'
import { CalendarFilters } from '../../sections/wizard/WizardReducer'

type Filters = {
  name: CalendarFilters
  label: string
  color: string
}[]

export function SidebarFilters() {
  const { t } = useTranslation(['core'])
  const { state, dispatch } = useWizard()

  const filters: Filters = [
    {
      name: 'buying',
      label: t('core:appointment_buying'),
      color: color('night-l-100')
    },
    {
      name: 'handover',
      label: t('core:appointment_handover'),
      color: color('sun-d-100')
    },
    {
      name: 'reclamation',
      label: t('core:appointment_reclamation'),
      color: color('warning')
    }
  ]

  const toggleFilter = (name: CalendarFilters) => {
    if (state.calendarFilters.includes(name)) {
      dispatch({
        type: 'SET_FILTERS',
        payload: state.calendarFilters.filter(filter => filter !== name)
      })
      return
    }

    dispatch({
      type: 'SET_FILTERS',
      payload: [...state.calendarFilters, name]
    })
  }

  return (
    <Flex variant="column" gap={4}>
      <TextBody
        variant="body"
        size="large"
        css={{
          color: color('night-d-200')
        }}
      >
        {t('core:appointments')}
      </TextBody>
      {filters.map(filter => {
        const isChecked = state.calendarFilters.includes(filter.name)

        return (
          <Checkbox
            name={filter.name}
            checked={isChecked}
            onChange={() => toggleFilter(filter.name)}
            label={filter.label}
            css={{ color: filter.color }}
          />
        )
      })}
    </Flex>
  )
}
