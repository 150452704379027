import { GetStaticProps } from 'next'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIntlProps } from '../utils/intl'

import IconLogout from 'images/IconLogout.svg'

import { endOfWeek, format, startOfWeek } from 'date-fns'

import { Flex } from 'driverama-core/components/Flex'

import { css } from '@emotion/react'
import { getBranchOpeningHours } from 'api/driverama/booking/branches'
import {
  getBookingSlotsFilters,
  useBookingSlotsQuery,
  useSlots
} from 'api/driverama/booking/slots'
import { Calendar } from 'components/calendar/Calendar'
import { SSidebarContent, SSidebarLayout } from 'components/layout/Layout'
import enGB from 'date-fns/locale/en-GB'
import { useEmployeesSelfQuery } from 'driverama-core/api/driverama/employees/self'
import { useActiveBranchList } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { AuthRedirectType } from 'driverama-core/auth/next'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { ErrorApi } from 'driverama-core/components/errorApi/ErrorApi'
import { SelectUncontrolled } from 'driverama-core/components/select/SelectUncontrolled'
import { TextBody } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { color } from 'driverama-core/styles/variables'
import { useWizard } from 'sections/wizard/WizardContext'
import {
  getOpeningDays,
  isRangeDateArray,
  mapDaysToDates
} from '../components/calendar/Calendar.utils'
import { useLogout } from '../components/header/profile/Profile.utils'
import { Sidebar } from '../components/sidebar/Sidebar'
import { SidebarButton } from '../components/sidebar/SidebarButton'
import { SidebarEmployee } from '../components/sidebar/SidebarEmployee'
import { SidebarFilters } from '../components/sidebar/SidebarFilters'
import { SidebarItem } from '../components/sidebar/SidebarItem'
import { LINKS } from '../constants/links'
import { EMPTY_ITEM } from '../sections/appointment/autocomplete/SelectAutocomplete.utils'
import { localizer } from '../utils/localizer'

export default function Booking() {
  const { t } = useTranslation(['core'])
  const branchListQuery = useActiveBranchList()
  const { dispatch, state } = useWizard()

  const logout = useLogout()
  const employeeSelf = useEmployeesSelfQuery()

  const date = useMemo(() => {
    return state.calendarDate ?? new Date()
  }, [state])

  const [calendarRange, setCalendarRange] = useState({
    start: startOfWeek(date, enGB.options),
    end: endOfWeek(date, enGB.options)
  })

  const shouldShowUnassignedEmployeeEvents =
    state.employeeFilters.includes(EMPTY_ITEM.type) ||
    !state.employeeFilters.length

  const bookingSlotsQuery = useBookingSlotsQuery(
    state.branchId ?? '',
    {
      filter: {
        dateFrom: format(calendarRange.start, 'yyyy-MM-dd'),
        dateTo: format(calendarRange.end, 'yyyy-MM-dd'),
        types: getBookingSlotsFilters(state.calendarFilters),
        bookingSlotCapacityRelevances: [],
        employeeIds: state.employeeFilters.includes(EMPTY_ITEM.type)
          ? [EMPTY_ITEM.type]
          : state.employeeFilters,
        includeWithoutEmployeeId: shouldShowUnassignedEmployeeEvents
      }
    },
    {
      enabled: !!state.branchId,
      cacheTime: 0,
      onError: err => {
        toast({
          type: 'error',
          content: (err as Error).toString(),
          error: null
        })
      }
    }
  )

  const { events, slots } = useSlots(bookingSlotsQuery.data)

  const queries = [branchListQuery, bookingSlotsQuery, events, employeeSelf]
  const isLoading = queries.some(query => query.isLoading)
  const isError = queries.some(query => query.isError)

  const branches = branchListQuery.branches

  const openingDaysWithHours = state.branchId
    ? getOpeningDays(
        mapDaysToDates(
          calendarRange.start,
          calendarRange.end,
          getBranchOpeningHours(branches, state.branchId)
        )
      )
    : undefined

  useEffect(() => {
    if (branches.length === 1) {
      dispatch({
        type: 'SET_BRANCH',
        payload: branches[0].id
      })
    }
  }, [branches, dispatch])

  useEffect(() => {
    dispatch({
      type: 'RESET_CUSTOMER'
    })
  }, [dispatch])

  const shouldRenderCalendar = !!state.branchId || isLoading

  const branch = branches.find(branch => branch.id === state.branchId)

  return (
    <SSidebarLayout>
      <Sidebar>
        <Flex
          variant="column"
          css={{
            flex: '1 100%'
          }}
          gap={8}
        >
          <SidebarItem
            css={css`
              width: 100%;
            `}
          >
            <SidebarButton branch={branch} />
          </SidebarItem>

          <SidebarEmployee disabled={!state.branchId} />

          {driverama.flags.IS_HANDOVER_ENABLED_APPS_31 && <SidebarFilters />}

          <Flex
            variant="column"
            justify="end"
            css={{
              flex: 1
            }}
          >
            <SidebarItem icon={IconLogout}>
              <ButtonTransparent onClick={logout}>
                <Flex variant="column" align="start">
                  <TextBody variant="body">{t('core:logout')}</TextBody>
                  <TextBody variant="caption">
                    {employeeSelf.data?.email}
                  </TextBody>
                </Flex>
              </ButtonTransparent>
            </SidebarItem>
          </Flex>
        </Flex>
      </Sidebar>
      <SSidebarContent>
        {isError ? (
          <ErrorApi
            title={t('core:error_api_title')}
            message={t('core:error_api_message')}
          />
        ) : (
          <Calendar
            isLoading={isLoading}
            openingDaysWithHours={openingDaysWithHours}
            slots={slots}
            localizer={localizer}
            view="week"
            onNavigate={date => {
              dispatch({
                type: 'SET_CALENDAR_DATE',
                payload: date
              })
            }}
            renderContent={shouldRenderCalendar}
            defaultView="week"
            step={30}
            timeslots={2}
            events={events.data}
            defaultDate={calendarRange.start}
            onRangeChange={range => {
              if (isRangeDateArray(range)) {
                const comparableRange = range[range.length - 1]

                setCalendarRange({
                  start: startOfWeek(comparableRange, enGB.options),
                  end: endOfWeek(comparableRange, enGB.options)
                })
              }
            }}
            toolbarLeftActions={
              <SelectUncontrolled
                emptyLabel={t('core:choose_branch')}
                name="branch"
                value={state.branchId}
                options={branches.map(x => ({
                  value: x.id,
                  label: x.name ?? ''
                }))}
                css={{ color: color('night-text') }}
                variant="rounded"
                onInnerChange={branch => {
                  if (branch) {
                    dispatch({
                      type: 'SET_BRANCH',
                      payload: branch
                    })
                  }
                }}
              />
            }
          />
        )}

        {!shouldRenderCalendar && (
          <TextBody
            variant="setup"
            css={{
              display: 'block',
              textAlign: 'center',
              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          >
            {t('core:choose_branch')}
          </TextBody>
        )}
      </SSidebarContent>
    </SSidebarLayout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['appointment', 'notes'])
})

Booking.auth = {
  type: AuthRedirectType.RequiresAuth,
  destination: LINKS.login
}
