import { Button } from 'driverama-core/components/button/Button'
import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, weight } from 'driverama-core/styles/variables'

export const SSidebarButtonWrapper = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`

export const SSidebarButtonMenuItem = styled.button`
  display: block;
  color: ${color('night-d-200')};
  padding: ${size(2)} ${size(3)};
  text-align: left;
  cursor: pointer;
  min-width: ${size(40)};

  &:hover {
    background: ${color('night-l-800')};
    font-weight: ${weight('medium')};
  }
`

export const SSidebarButton = styled(Button)`
  padding: 0;
  width: 100%;
`

export const STextWrapper = styled.div`
  position: relative;
  padding: ${size(4)};
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: ${color('white', 0.25)};
  }
`

export const SIconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  padding: ${size(4)};
  color: ${color('white')};
`
