import { FunctionComponent, PropsWithChildren } from 'react'
import { Flex } from 'driverama-core/components/Flex'
import { css } from '@emotion/react'
import { color } from 'driverama-core/styles/variables'

interface SidebarItem {
  icon?: FunctionComponent
}

export function SidebarItem({
  icon: Icon,
  children
}: PropsWithChildren<SidebarItem>) {
  return (
    <Flex
      variant="row"
      gap={4}
      align="start"
      css={css`
        width: 100%;
      `}
    >
      {Icon && (
        <Icon
          css={css(`
        color: ${color('night-l-100')}
      `)}
        />
      )}

      {children}
    </Flex>
  )
}
