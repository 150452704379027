import { useEmployeesSearchQuery } from '../../api/driverama/employees/search'
import { isNotNil } from 'driverama-core/utils/types'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { SelectAutocomplete } from '../../sections/appointment/autocomplete/SelectAutocomplete'
import { useWizard } from '../../sections/wizard/WizardContext'
import {
  EMPTY_ITEM,
  isEmptyItem,
  isExtraItem
} from '../../sections/appointment/autocomplete/SelectAutocomplete.utils'
import { useTranslation } from 'react-i18next'

import IconCheck from 'driverama-core/images/icons/IconCheck.svg'

interface SidebarEmployeeProps {
  disabled?: boolean
}

export function SidebarEmployee(props: SidebarEmployeeProps) {
  const { state, dispatch } = useWizard()
  const { t } = useTranslation()

  const { data, isLoading } = useEmployeesSearchQuery({
    ids: []
  })

  return (
    <SelectAutocomplete
      icon
      value={
        state.employeeFilters.includes(EMPTY_ITEM.type)
          ? t('unassigned')
          : state.employeeFilters
              .map(filter =>
                data?.content.find(employee => employee.id === filter)
              )
              .map(employee =>
                [employee?.firstName, employee?.surname]
                  .filter(isNotNil)
                  .join(' ')
              )
              .join(', ')
      }
      label={t('filter_by_employee')}
      disabled={props.disabled || isLoading}
      fuseOptions={{
        keys: ['fullName'],
        threshold: 0.4
      }}
      data={data?.content.map(employee => ({
        ...employee,
        fullName: [employee?.firstName, employee?.surname]
          .filter(isNotNil)
          .join(' ')
      }))}
      onSelect={item => {
        if (!isExtraItem(item)) {
          const isSelected = state.employeeFilters.includes(item.id)
          if (isSelected) {
            dispatch({
              type: 'SET_EMPLOYEE_FILTER',
              payload: []
            })
          } else {
            dispatch({
              type: 'SET_EMPLOYEE_FILTER',
              payload: [item.id]
            })
          }
        }

        if (isEmptyItem(item)) {
          const isSelected = state.employeeFilters.includes(EMPTY_ITEM.type)
          if (isSelected) {
            dispatch({
              type: 'SET_EMPLOYEE_FILTER',
              payload: []
            })
          } else {
            dispatch({
              type: 'SET_EMPLOYEE_FILTER',
              payload: [EMPTY_ITEM.type]
            })
          }
        }
      }}
      emptyItem={() => {
        return (
          <Flex variant="row" gap={2} align="center">
            {state.employeeFilters.includes(EMPTY_ITEM.type) && <IconCheck />}
            <TextBody>{t('unassigned')}</TextBody>
          </Flex>
        )
      }}
      item={values => {
        const isSelected = state.employeeFilters.includes(values.id)
        return (
          <Flex variant="row" gap={2} align="center">
            {isSelected && <IconCheck />}
            <TextBody>
              {[values.firstName, values.surname].filter(isNotNil).join(' ')}
            </TextBody>
          </Flex>
        )
      }}
    />
  )
}
