import { paths } from 'driverama-core/api/driverama/generated/employees'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type EmployeeSelfResponse = paths['/employees/self']['get']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryOpts = UseQueryOptions<unknown, unknown, EmployeeSelfResponse>

export function useEmployeesSelfQuery(opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.employeeSelf,
    async () => {
      const res = await apiAuthFetcher<EmployeeSelfResponse>(
        URLS.employeeSelf,
        {
          method: HTTPMethod.GET
        }
      )
      return res.json
    },
    opts
  )
}
