import { LINKS } from 'constants/links'
import { useRouter } from 'next/router'

export function useLogout() {
  const router = useRouter()

  async function handleLogout() {
    await router.push(LINKS.logout)
  }

  return () => handleLogout()
}
